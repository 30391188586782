import React from 'react'
import { Helmet } from 'react-helmet'
import {
  PrismicImageType,
  PrismicPageDataType,
  PrismicProjectDataType,
  PrismicPageBodyMedia,
  PrismicProjectBodyMedia,
  PrismicPageBodyMediaItemType,
  PrismicProjectBodyMediaItemType,
} from '../../graphql-types'
import { useMetadata } from '../hooks/useMetadata'

const SEO = ({
  data,
  override,
  type,
  uid,
}: {
  data?: PrismicPageDataType | PrismicProjectDataType | null
  override?: {
    images?: (PrismicImageType | null | undefined)[]
    title?: string
    meta_description?: string
  }
  type: string
  uid: string | null | undefined
}) => {
  const { prismicMetadata } = useMetadata(),
    siteDomain =
      prismicMetadata?.data?.site_domain ?? `https://lukeedwardhall.com`,
    siteTitle = prismicMetadata?.data?.site_title ?? `Luke Edward Hall`,
    siteTitleDescription =
      prismicMetadata?.data?.site_title_description ??
      `Artist, Designer and Columnist`,
    siteTitleDivider = prismicMetadata?.data?.site_title_divider ?? `|`

  const title =
    override?.title || data?.title
      ? `${override?.title || data?.title} ${siteTitleDivider} ${siteTitle}`
      : `${siteTitle} ${siteTitleDivider} ${siteTitleDescription}`

  let texts = null,
    description = override?.meta_description ?? data?.meta_description ?? null,
    images = override?.images ?? null,
    canonical = null,
    titleTags = null,
    canonicalTags = null,
    descriptionTags = null

  if (data?.body) {
    texts = (data.body as (PrismicPageBodyMedia | PrismicProjectBodyMedia)[])
      ?.flatMap((slice: PrismicPageBodyMedia | PrismicProjectBodyMedia) =>
        (slice?.items as (
          | PrismicPageBodyMediaItemType
          | PrismicProjectBodyMediaItemType
        )[])?.flatMap(
          (
            item: PrismicPageBodyMediaItemType | PrismicProjectBodyMediaItemType
          ) => item?.text?.text
        )
      )
      .filter((text: string | null | undefined) => text)
    description = (texts as string[]).sort((a, b) => b.length - a.length)[0]
    images = (data.body as (PrismicPageBodyMedia | PrismicProjectBodyMedia)[])
      ?.flatMap((slice: PrismicPageBodyMedia | PrismicProjectBodyMedia) =>
        (slice?.items as (
          | PrismicPageBodyMediaItemType
          | PrismicProjectBodyMediaItemType
        )[])?.flatMap(
          (
            item: PrismicPageBodyMediaItemType | PrismicProjectBodyMediaItemType
          ) => item?.image
        )
      )
      .filter((image: PrismicImageType | null | undefined) => image?.fluid)
  }

  switch (type) {
    case `page`:
      canonical = `${siteDomain}/${uid}`
      break
    case `project`:
    case `category`:
      canonical = `${siteDomain}/projects/${uid}`
      break
    default:
  }

  if (title) {
    titleTags = [
      <title key="title">{override?.title || data?.title}</title>,
      <meta key="og:title" property="og:title" content={title} />,
    ]
  }

  if (description) {
    descriptionTags = [
      <meta key="description" name="description" content={description} />,
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />,
    ]
  }

  if (type === `page` && uid === `index`) {
    canonical = siteDomain
    titleTags = null
  }

  if (canonical) {
    canonicalTags = [
      <link key="canonical" rel="canonical" href={canonical} />,
      <meta key="og:url" property="og:url" content={canonical} />,
    ]
  }

  return (
    <Helmet>
      {titleTags}
      {canonicalTags}
      {descriptionTags}

      {images?.flatMap((image, i) => [
        <meta
          key={
            image?.fluid?.src?.replace(`https://`, `http://`) ??
            `og:image:url-${i}`
          }
          property="og:image"
          content={
            image?.fluid?.src?.replace(`https://`, `http://`)?.split(`?`)[0] +
            `?auto=compress,format&dpr=1&fit=max&q=50&w=2400`
          }
        />,
        <meta
          key={image?.fluid?.src ?? `og:image:secure_url-${i}`}
          property="og:image:secure_url"
          content={
            image?.fluid?.src?.split(`?`)[0] +
            `?auto=compress,format&dpr=1&fit=max&q=50&w=2400`
          }
        />,
        <meta
          key={image?.dimensions?.width ?? `og:image:width-${i}`}
          property="og:image:width"
          content={
            image?.dimensions?.width
              ? image?.dimensions?.width < 2400
                ? `${image?.dimensions?.width}`
                : `2400`
              : undefined
          }
        />,
        <meta
          key={image?.dimensions?.height ?? `og:image:height-${i}`}
          property="og:image:height"
          content={
            image?.dimensions?.width
              ? image?.dimensions?.width < 2400
                ? `${image?.dimensions?.height}`
                : `${Math.round(
                    (2400 * image?.dimensions?.height) /
                      image?.dimensions?.width
                  )}`
              : undefined
          }
        />,
        image?.alt ? (
          <meta key={image.alt} property="og:image:alt" content={image.alt} />
        ) : null,
      ])}
    </Helmet>
  )
}

export default SEO
export { SEO }
