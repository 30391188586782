import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import Img, { FluidObject, GatsbyImageProps } from 'gatsby-image'
import {
  PrismicProjectEdge,
  PrismicProject,
  PrismicProjectBodyMedia,
} from '../../graphql-types'
import { SEO } from './seo'
import { determineDuration } from '../utilities'

const ProjectThumbnail = ({
  context,
  project,
}: {
  context: string
  project: PrismicProject
}) => {
  const data = project?.data,
    startDate = data?.start_date
      ? new Date(`${data.start_date}T00:00:00.000+00:00`)
      : undefined,
    endDate = data?.end_date
      ? new Date(`${data.end_date}T00:00:00.000+00:00`)
      : undefined,
    duration = useMemo(() => determineDuration(startDate, endDate), [
      startDate,
      endDate,
    ]),
    fluid = data?.featured_image?.fluid
      ? (data?.featured_image?.fluid as FluidObject)
      : (data?.featured_image?.localFile?.childImageSharp?.fluid as FluidObject)

  // Set Img sizes based on styling
  if (fluid)
    fluid.sizes = `
    (min-width: 640px) calc((100vw - 1rem) / 3 - 1rem),
    (min-width: 1024px) calc(((100vw - 12rem) / 4) - 2rem),
    calc((100vw - 1rem) / 2 - 1rem)
  `

  const imageProps: GatsbyImageProps = fluid
    ? {
        fluid: fluid,
        fadeIn: false,
        imgStyle: {
          objectFit: `contain`,
        },
        placeholderStyle: {
          left: `50%`,
          maxWidth:
            fluid.aspectRatio > 1 ? `100%` : `${fluid.aspectRatio * 100}%`,
          maxHeight:
            fluid.aspectRatio < 1 ? `100%` : `${fluid.aspectRatio * 100}%`,
          objectFit: `cover`,
          transform: `translateX(-50%)`,
        },
      }
    : {}

  return (
    <Link
      key={`${project?.uid}`}
      to={`/projects/${project?.uid}`}
      state={{ context }}
      data-state={JSON.stringify({ context })}
      className="w-1/2 sm:w-1/3 lg:w-1/4 p-2 lg:p-4 flex-col inline-flex items-center justify-center"
    >
      {fluid && <Img className="w-full mb-2" {...imageProps} />}
      <div className="text-center">
        <div className="text-xs text-default uppercase">{duration}</div>
        <div className="text-xl xl:text-2xl italic ">{data?.title}</div>
      </div>
    </Link>
  )
}

const Category = ({
  category,
  pathname,
}: {
  category: {
    edges: PrismicProjectEdge[]
  }
  pathname: string
}) => {
  const uid = pathname.replace(`/projects/`, ``).replace(`/`, ``),
    context = `${uid.substr(0, 1).toUpperCase()}${uid.substr(1)}`,
    projectNames = category?.edges
      ?.map((edge) => edge?.node?.data?.title)
      .slice(0, 4),
    projectNamesString =
      projectNames.length === 1
        ? projectNames
        : projectNames.length === 2
        ? projectNames.join(` and `)
        : projectNames.slice(0, projectNames.length - 1).join(`, `) +
          ` and ${projectNames[projectNames.length - 1]}`,
    projectImages = category?.edges?.flatMap((edge) => {
      const firstImageSlice:
          | PrismicProjectBodyMedia
          | null
          | undefined = edge?.node?.data?.body?.find((slice) =>
          slice?.items?.find((item) => item?.image?.fluid)
        ),
        firstImageItem = firstImageSlice?.items?.find(
          (item) => item?.image?.fluid
        ),
        firstImage = firstImageItem?.image
      return firstImage
    }),
    override = {
      images: projectImages,
      title: context === `All` ? `Projects` : context,
      meta_description: `Explore ${
        context === `All` ? `projects` : context.toLowerCase()
      } by Luke Edward Hall including ${projectNamesString}.`,
    }

  return (
    <div className="category min-h-screen pb-24 px-2 lg:p-24">
      <SEO type="category" uid={uid} override={override} />
      <div className="flex items-start flex-wrap">
        {category?.edges?.map((edge: PrismicProjectEdge, i: number) =>
          edge?.node ? (
            <ProjectThumbnail
              key={edge?.node?.uid || i}
              context={context}
              project={edge?.node}
            />
          ) : null
        )}
      </div>
    </div>
  )
}

export default Category
export { Category }
