import React from 'react'
import { useAllCategoryProjects } from '../hooks/useAllProjects'
import { WindowLocation } from '@reach/router'
import { Category } from '../components/category'

interface CategoryTemplateProps {
  location: WindowLocation
  pageContext: any
}

function CategoryTemplate({
  pageContext: { category },
  location: { pathname },
}: CategoryTemplateProps) {
  const categoryProjects = useAllCategoryProjects(category)

  return <Category category={categoryProjects} pathname={pathname} />
}

export default CategoryTemplate
